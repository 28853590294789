/*
 * @Author: huangbinbin
 * @Date: 2024-07-24 09:25:21
 * @LastEditors: huangbinbin
 * @LastEditTime: 2024-08-16 10:03:29
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import score1 from '../views/score3.vue'
// import score2 from '../views/score2.vue'
// import score3 from '../views/score3.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: score1
  },
  // {
  //   path: '/score2',
  //   name: 'home',
  //   component: score2
  // },
  // {
  //   path: '/score3',
  //   name: 'home',
  //   component: score3
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
